<template>
    <div>Esporta Comunicazioni</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Comunicazioni", route: "/circular/index" },
            { title: "Esporta Comunicazioni" }
        ]);
    }
};
</script>
